
export const extractLocalName = (name) =>  {
    const index = name.lastIndexOf('[');
    if (index < 0) {
      return name;
    }
    return name.substring(0, index);
};

export const makeInputName = (template, name) => {
    return template.replace(':name', name);
};