import { Controller } from "stimulus";

import { extractLocalName, makeInputName} from '../src/html_utils';

import { addHiddenFieldTo } from "../src/form_helper";

import SubmitAware from "../src/submit_button_aware";

export default class extends Controller {
  static targets = [ "dropin", "form", "plan" ]

  connect() {
    this.submitController = SubmitAware.install(this);
    this.inputNameTemplate = this.hasPlanTarget ? (extractLocalName(this.planTarget.name) + '[:name]') : 'account[:name]';
    braintree.dropin.create({
      authorization: this.data.get("clientToken"),
      container: this.dropinTarget,
      //threeDSecure: true,
      paypal: {
        flow: "vault"
      },
      // Uncomment this to only display PayPal in the Drop-in UI
      //paymentOptionPriority: ['paypal']
    },
      this.clientCreated.bind(this)
    )
  }

  clientCreated(error, instance) {
    if (error) {
      console.error("Error setting up Braintree dropin:", error)
      return
    }

    this.instance = instance
  }

  reenableSubmit() {
    const event = new CustomEvent('app:signup_enable', {detail: {processor: 'braintree', target: this}});
    window.dispatchEvent(event);
    this.submitController.enable();
  }


  submit(event) {
    event.preventDefault();
    this.submitController.disable();
    this.instance.requestPaymentMethod(this.paymentMethod.bind(this))
  }

  submitForForm(formElement) {
    this.realForm = formElement;
    if (this.processing) {
      return;
    }
    if (this.tokenized) {
      Rails.fire(formElement, "submit");
      return;
    }
    
    this.processing = true;
    this.instance.requestPaymentMethod(this.paymentMethod.bind(this))
  }

  paymentMethod(error, payload) {
    this.processing = false;
    if (error) {
      console.error("Error with payment method:", error);
      this.reenableSubmit();
      return
    }

    this.tokenized = true;

    this.addHiddenField(makeInputName(this.inputNameTemplate, 'processor'), "braintree");

    this.addHiddenField(makeInputName(this.inputNameTemplate, 'card_token'), payload.nonce)

    Rails.fire(this.realForm || this.formTarget, "submit");
  }

  addHiddenField(name, value) {
    addHiddenFieldTo(this.formTarget, name, value);
  }
}
