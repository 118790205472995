import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [
        'cancelButton', 'continueButton', 'submitForm', 'alertElement', 'disconnectButton'
    ];

    connect() {
        
    }


    promptDisconnect(event) {
        event.target.classList.add('hidden');
        this.alertElementTarget.classList.remove('hidden');
    }

    cancelDisconnect() {
        this.alertElementTarget.classList.add('hidden');
        this.disconnectButtonTarget.classList.remove('hidden');
    }

    continueWithDisconnect(event) {
        this.submitFormTarget.submit();
    }
}