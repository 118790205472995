import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ['progress'];

    connect() {
        this.progressTarget.classList.add('hidden');
        console.log('Connected to shared actions');
    }

    showProgress(event) {
        event.target.classList.add('hidden');
        this.progressTarget.classList.remove('hidden');
    }
}