import { capitalize, isEmpty, isFunction } from "lodash";
import { Controller } from "stimulus";
import { isPromise, ValidationMessageHelper, Validators } from "../src/validation";


export default class extends Controller {
    static targets = ["input", "errorContainer"];
    

    validator = function() {return {}};

    _parentController = null;

    constructor(context) {
        super(context);
        this.onBlur = this.onBlur.bind(this);
        this.clearErrorMessages = this.clearErrorMessages.bind(this);
    }


    get label()  {
        if (this.data.has("label")) {
            return this.data.get("label");
        }
        const p = this.inputTarget.parentElement.querySelector("label");
        if (p) {
            return p.textContent;
        }
        let name = this.inputTarget.name;
        const index = name.lastIndexOf("[");
        if (index > 0) {
            name = name.substring(index + 1, name.indexOf("]", index));
        }
        return capitalize(name.replace(/_/g, " ").replace(/([a-z])([A-Z])/, "$1 $2"));
    }

    onBlur() {
        this.validate();
    }


    connect() {
        this.inputTarget.addEventListener("blur", this.onBlur);
        const rules = this.data.get("rules").split("|").map(x => this.parseRule(x));
        this.inputTarget.addEventListener("focus", this.clearErrorMessages);
        this.validator = Validators.compose(rules, true);
    }

    disconnect() {
        this.inputTarget.removeEventListener("blur", this.onBlur);
        this.inputTarget.removeEventListener("focus", this.clearErrorMessages);
    }


    get parentController() {
        return this._parentController;
    }

    set parentController(c) {
        this._parentController = c;
        const rules = this.data.get("rules").split("|").map(x => this.parseRule(x));
        this.validator = Validators.compose(rules, true);
    }
    

    lookupParentController(atElement, name, level) {
        const controller = this.application.getControllerForElementAndIdentifier(atElement, name);
        if (controller) {
            return controller;
        }
        if (level < 4) {
            return this.lookupParentController(atElement.parentElement, name, level + 1);
        }
        return null;

    }

    parseRule(rule) {
        const [key, args] = rule.split(":");
        if (Validators[key]) {
            if (args) {
                return Validators[key](args);
            }
            return Validators[key];
        }
        
        if (rule.indexOf("#") > 0) {
            
            const [controllerName, method] = rule.split("#");

            const controller = this.lookupParentController(this.element.parentElement, controllerName, 0);

            if (!controller) {
                return function () {
                    return {
                        [`invalidaValidationFor${method}`]: {
                            message: `No validation method ${method}. ${controllerName} controller was not found`
                        }
                    };
                }
            }

            if (isFunction(controller[method])) {
                return function (input, ctx) {
                    return controller[method](input, ctx);
                }
            }
            return function () {
                return {
                    [`invalidaValidationFor${method}`]: {
                        message: `No validation method ${method} found in ${controllerName} controller`
                    }
                };
            }
        }

        if (this.parentController) {
            if (isFunction(this.parentController[rule])) {
                return (input, ctx) => {
                    return this.parentController[rule](input, ctx);
                };
            }

        }

        return function (_) {
            return {
                invalidValidation: {
                    message: `Validator for ${rule} is not defined`
                }
            }
        }
    }




    get validationRules() {
        return (this.data.get("rules") || "").split("|");
    }


    handleValidationMessages(result) {
        const failed = !isEmpty(result);
        this.clearErrorMessages();
        if (failed) {
            if (!this.inputTarget.classList.contains("error")) {
                this.inputTarget.classList.add("error")
            }
        } 
        const errorMessages = ValidationMessageHelper.generateMessages(result, {label: this.label});
        errorMessages.forEach(msg => {
            const p = document.createElement("p");
            p.className = "form-hint error";
            p.textContent = msg;
            this.errorContainerTarget.appendChild(p);
        });

    }


    clearErrorMessages() {
        this.inputTarget.classList.remove("error");
        while (this.errorContainerTarget.firstChild) {
            this.errorContainerTarget.removeChild(this.errorContainerTarget.firstChild);
        }
    }


    validate() {
        const result = this.validator(this.inputTarget, {});

        if (isPromise(result)) {
            return result.then((r) => {
                this.handleValidationMessages(r);
                return isEmpty(r);
            });
        }

        this.handleValidationMessages(result);

        return isEmpty(result);

    }

}