import { Controller } from "stimulus";
import axios from 'axios';
import { trim } from 'lodash';

import { toPromise } from "../src/validation";





export default class extends Controller {
    static targets = ['userFields', "validationContext", "form", 'finalFields',
     'fullNameError','usernameField', 'fullNameError', "submitButton"];

    errors = {};


    fullNameValidator(input) {
        if (!input.value) {
            return null;
        }
        const value = (input.value || "").split(/\s+/g).map(trim).filter(x => x.length > 0);

        if (value.length < 2) {
            return {
                fullName: {
                    message: "Must be a full name"
                }
            };
        }
        return null;
    }
    

    async validateAll() {
        const results = await Promise.all(this.validationContextTargets.map((target) => {
            const controller = this.application.getControllerForElementAndIdentifier(target, "validation-context");
            return toPromise(controller.validate());
        }));
        return results.every(x => x === true);
    }
    
    connect() {
        // const debouncedSubdomainChecked =  debounce(this.checkSubdomainExists.bind(this), 400);
       
    }





    validateFullName() {
        if (this.isFullName()) {
            if (!this.fullNameErrorTarget.classList.contains('hidden')) {
                this.fullNameErrorTarget.classList.add('hidden');
            }
        } else if (this.fullNameErrorTarget.classList.contains('hidden')) {
            this.fullNameErrorTarget.classList.remove('hidden')
        }
    }

    isFullName() {
        const value = this.usernameFieldTarget.value;
        if (value) {
            const parts = value.split(/\s+/g).filter(a => a.length > 0);
            return parts.length > 1;
        }
        // Don't validate if empty
        return true;
    }




   

    async uniqueEmail(input) {
        if (!input.value) {
            return null;
        }


        const value = trim(input.value);

        
        try {
            const r = await axios.get('/users/exists.json', {
                params: {
                    email: value
                }
            });
            const exists = r.data.exists;
            if (exists) {
                return {
                    uniqueEmail: {
                        message: `Email, '${value}', is in use`
                    }
                };
            }    
        } catch (error) {
            return {
                uniqueEmail: {
                    message: `An error occurred while checking email address`
                }

            };
        }
        
        return null;

    }


    



    disableSubmit() {
        this.submitButtonTarget.classList.add("disabled");
        this.submitButtonTarget.setAttribute("disabled", "");
        this.submitButtonTarget.innerText = this.submitButtonTarget.getAttribute("data-disable-with");
    }

    enableSubmit() {
        this.submitButtonTarget.classList.remove("disabled");
        this.submitButtonTarget.removeAttribute("disabled");
        this.submitButtonTarget.innerText = this.submitButtonTarget.getAttribute("data-default-text");
    }

    submit(event) {
        event.preventDefault();
        this.disableSubmit();
        this.validateAll().then((ok) => {
            if (ok) {
                Rails.fire(this.formTarget, "submit");
            } else {
                this.enableSubmit();
            }
        })
    }



}