
export function addHiddenFieldTo(target, name, value) {
    let input = target.querySelector(`input[name="${name}"]`);
    if (!input) {
        input = document.createElement("input");
        input.type = "hidden";
        input.name = name;
        target.appendChild(input);
    }
    input.value = value;

    return this;

}