import { Controller } from "stimulus";
import axios from 'axios';
import { debounce, isUndefined, trim } from 'lodash';

import { toPromise } from "../src/validation";


const CURRENCY_COUNTRY_MAP = {
    'usd': ['us', 'usd'],
    'cad': ['ca', 'can'],
    'ngn': ['ng'],
    'yen': ['jp'],
    'eur': 'al,ad,at,by,be,ba,bg,hr,cy,cz,dk,ee,fo,fi,fr,de,gi,gr,hu,is,ie,it,lv,li,lt,lu,mk,mt,md,mc,nl,no,pl,pt,ro,ru,sm,rs,sk,si,es,se,ch,ua,gb,va,rs,im,rs,me'.split(',')
};


const getDefaultCurrency = (country) => {
    country = country.toLowerCase();
    let value = Object.keys(CURRENCY_COUNTRY_MAP).find(currency => {
        const countries = CURRENCY_COUNTRY_MAP[currency];
        return countries.indexOf(country) >= 0;
    });
    if (!value) {
        return getDefaultCurrency('us');
    }
    return value;
};


export default class extends Controller {
    static targets = ['userFields', "validationContext", 'accountFields', "form", 'finalFields', "formTarget",
     'regionFields', 'fullNameError', 'subdomainLabel', 'subdomainExistsHint', 'subdomainError',
      "subdomainHint", 'countryField', 'currencyField', 'subdomainField','usernameField', 'fullNameError', "submitButton"];

    errors = {};

    subdomainExists = false;


    fullNameValidator(input) {
        if (!input.value) {
            return null;
        }
        const value = (input.value || "").split(/\s+/g).map(trim).filter(x => x.length > 0);

        if (value.length < 2) {
            return {
                fullName: {
                    message: "Must be a full name"
                }
            };
        }
        return null;
    }
    

    async validateAll() {
        const results = await Promise.all(this.validationContextTargets.map((target) => {
            const controller = this.application.getControllerForElementAndIdentifier(target, "validation-context");
            return toPromise(controller.validate());
        }));
        return results.every(x => x === true);
    }
    
    connect() {
        // const debouncedSubdomainChecked =  debounce(this.checkSubdomainExists.bind(this), 400);
        // this.subdomainFieldTarget.addEventListener('blur', this.checkSubdomainExists.bind(this));
        // this.subdomainFieldTarget.addEventListener('input', debouncedSubdomainChecked);
        this.subdomainFieldTarget.addEventListener("input", this.renderUrlPreview.bind(this));

        this.subdomainFieldTarget.addEventListener("blur", (e) => {
            if (e.target.value) {
                e.target.value = e.target.value.toLowerCase();
            }
        });
        
        // this.subdomainFieldTarget.addEventListener('blur', this.checkSubdomainExists.bind(this));
        // this.usernameFieldTarget.addEventListener('blur', this.validateFullName.bind(this));
    }





    validateFullName() {
        if (this.isFullName()) {
            if (!this.fullNameErrorTarget.classList.contains('hidden')) {
                this.fullNameErrorTarget.classList.add('hidden');
            }
        } else if (this.fullNameErrorTarget.classList.contains('hidden')) {
            this.fullNameErrorTarget.classList.remove('hidden')
        }
    }

    isFullName() {
        const value = this.usernameFieldTarget.value;
        if (value) {
            const parts = value.split(/\s+/g).filter(a => a.length > 0);
            return parts.length > 1;
        }
        // Don't validate if empty
        return true;
    }


    renderUrlPreview() {
        const subdomain = this.subdomainFieldTarget.value;
        if (subdomain) {
            this.subdomainLabelTarget.innerText = subdomain.toLowerCase();
        } else {
            this.subdomainLabelTarget.innerText = "<subdomain>";
        }
    }


    revealSettingsFields() {
        // this.regionFieldsTarget.classList.remove('hidden');
        const defaults = { 'country': 'US', 'currency': 'usd' };
        Object.keys(defaults).forEach((key) => {
            const el = this[`${key}FieldTarget`];
            if (!el.value) {
                el.value = defaults[key];
            }
        });
    }

    async uniqueSubdomain(input) {
        if (!input.value) {
            return null;
        }

        try {
            const value = trim(input.value);

            const r = await axios.get('/accounts/exists.json', {
                params: {
                    subdomain: value
                }
            });
            const exists = r.data.exists;
            if (exists) {
                return {
                    uniqueSubdomain: {
                        message: `Subdomain, '${value}', is in use`
                    }
                };
            }    
        } catch (error) {
            return {
                uniqueSubdomain: {
                    message: "Failed to check for existing subdomain"
                }
            };
        }
        
        return null;

    }

    async uniqueEmail(input) {
        if (!input.value) {
            return null;
        }


        const value = trim(input.value);

        
        try {
            const r = await axios.get('/users/exists.json', {
                params: {
                    email: value
                }
            });
            const exists = r.data.exists;
            if (exists) {
                return {
                    uniqueEmail: {
                        message: `Email, '${value}', is in use`
                    }
                };
            }    
        } catch (error) {
            return {
                uniqueEmail: {
                    message: `An error occurred while checking email address`
                }

            };
        }
        
        return null;

    }


    

    checkSubdomainExists(event) {
        const value = event.target.value;

        if (!value) {
            return this.notifySubdomainExists(false);
        }
        axios.get('/accounts/exists.json', {
            params: {
                subdomain: value
            }
        }).then((r) => r.data.exists)
        .then((exists) => {
            this.notifySubdomainExists(exists);
        });
    }

    notifySubdomainExists(exists) {
        if (this.subdomainExists === exists) {
            return;
        }
        if (exists) {
            this.errors.subdomain = true;
        } else if (!isUndefined(this.errors.subdomain)) {
            delete this.errors.subdomain;
        }

        this.subdomainExists = exists;
        const el = this.subdomainExistsHintTarget;
        if (!exists) {
            if (!el.classList.contains("hidden")) {
                el.classList.add('hidden');
            }
            
        } else {
            if (el.classList.contains("hidden")) {
                el.classList.remove('hidden');
            }
        } 
    }

    disableSubmit() {
        this.submitButtonTarget.classList.add("disabled");
        this.submitButtonTarget.setAttribute("disabled", "");
        this.submitButtonTarget.innerText = this.submitButtonTarget.getAttribute("data-disable-with");
    }

    enableSubmit() {
        this.submitButtonTarget.classList.remove("disabled");
        this.submitButtonTarget.removeAttribute("disabled");
        this.submitButtonTarget.innerText = this.submitButtonTarget.getAttribute("data-default-text");
    }

    submit(event) {
        event.preventDefault();
        this.disableSubmit();
        this.validateAll().then((ok) => {
            if (ok) {
                Rails.fire(this.formTarget, "submit");
            } else {
                this.enableSubmit();
            }
        })
    }



}