import { Controller } from "stimulus";
import { extractLocalName, makeInputName} from '../src/html_utils';
import { addHiddenFieldTo } from "../src/form_helper";
import submit_aware from "../src/submit_button_aware";

export default class extends Controller {
  static targets = [ "card", "name", "error", "form", "plan" ]

  connect() {
    this.submitController = submit_aware.install(this);

    let stripeMeta = document.querySelector('meta[name="stripe-key"]')
    if (stripeMeta === null) { return }

    let stripeKey = stripeMeta.getAttribute("content")
    this.stripe   = Stripe(stripeKey)
    let elements  = this.stripe.elements()

    // Setup Intents are used for adding new cards to be charged in the future
    this.setup_intent = this.data.get("setup-intent")

    // Payment intents are for processing payments that require action
    this.payment_intent = this.data.get("payment-intent")

    // Setup regular payments
    this.card = elements.create("card")
    this.card.mount(this.cardTarget)
    this.card.addEventListener("change", this.changed.bind(this));

    this.inputNameTemplate = this.hasPlanTarget ? (extractLocalName(this.planTarget.name) + '[:name]') : 'account[:name]';
    
  }

  changed(event) {
    if (event.error) {
      this.errorTarget.textContent = event.error.message
    } else {
      this.errorTarget.textContent = ""
    }
  }

  

  keydown(event) {
    if (event.keyCode == 13) {
      // Catch Enter key's form submission and process as submit
      event.preventDefault()
      this.submit(event)
    }
  }

  submit(event) {
    event.preventDefault();
    this.submitController.disable();
    if (this.nameTarget.value == "") {
      this.errorTarget.textContent = "Name on card is required.";
      this.reenableSubmit();
      return
    }

    // One time payments
    if (this.payment_intent) {
      this.handleCardPayment()

      // Updating card with setup intent
    } else if (this.setup_intent) {
      this.setupNewCard()

    // Subscriptions simply tokenize the payment method and redirect to payment page if SCA required
    } else {
      this.stripe.createPaymentMethod({
        type: 'card',
        card: this.card,
        billing_details: {
          name: this.nameTarget.value
        },
      }).then((result) => this.handlePaymentMethod(result.paymentMethod.id))
    }
  }

  reenableSubmit() {
    const event = new CustomEvent('app:signup_enable', {detail: {processor: 'stripe', target: this}});
    this.submitController.enable();
    window.dispatchEvent(event);
  }

  submitForForm(formElement) {
    if (this.processing) {
      return;
    }
    if (this.tokenized) {
      Rails.fire(formElement, "submit");
      return;
    }

    if (this.nameTarget.value == "") {
      this.errorTarget.textContent = "Name on card is required.";
      this.reenableSubmit();
      return
    }

    // One time payments
    if (this.payment_intent) {
      this.handleCardPayment()

      // Updating card with setup intent
    } else if (this.setup_intent) {
      this.setupNewCard(formElement)

    // Subscriptions simply tokenize the payment method and redirect to payment page if SCA required
    } else {
      this.stripe.createPaymentMethod({
        type: 'card',
        card: this.card,
        billing_details: {
          name: this.nameTarget.value
        },
      }).then((result) => this.handlePaymentMethod(result.paymentMethod.id, formElement))
    }

  }

  setupNewCard(formElement = null) {
    let data = {
      payment_method: {
        card: this.card,
        billing_details: {
          name: this.nameTarget.value
        }
      }
    }
    this.processing = true;
    this.stripe.confirmCardSetup(this.setup_intent, data).then((result) => {
      this.processing = false;
      if (result.error) {
        this.errorTarget.textContent = result.error.message;
        this.reenableSubmit();
      } else {
        this.handlePaymentMethod(result.setupIntent.payment_method, formElement)
      }
    })
  }

  handlePaymentMethod(payment_method_id, formElement = null) {
    if (payment_method_id) {
      this.tokenized = true;
      
      this.addHiddenField(makeInputName(this.inputNameTemplate, 'processor'), "stripe");

      this.addHiddenField(makeInputName(this.inputNameTemplate, 'card_token'), payment_method_id);

      Rails.fire(formElement || this.formTarget, "submit");
    } else {
      this.errorTarget.textContent = "An unknown error occurred while processing card information";
      this.submitController.enable();
    }
  }

  addHiddenField(name, value) {
    addHiddenFieldTo(this.formTarget, name, value);
  }

  handleCardPayment() {
    // Handle an existing payment that needs confirmation
    this.stripe.confirmCardPayment(this.payment_intent).then((result) => {
      if (result.error) {
        this.errorTarget.textContent = result.error.message;
        this.reenableSubmit();
      } else if (result.paymentIntent && result.paymentIntent.status === 'succeeded') {
        Turbolinks.clearCache()
        Turbolinks.visit("/")
      }
    })
  }
}
