import { Controller } from "stimulus";
import consumer from '../channels/consumer';

export default class extends Controller {
    static targets = ['progressIcon', 'complete'];
    connect() {
        consumer.subscriptions.create({
            channel: 'AccountChannel',
            event: 'setup',
            account: this.element.getAttribute('data-account')
        }, {
            received: (data) => {
                if (data.phase === 'started') {
                    this.handleStarted();
                } else if (data.phase === 'completed') {
                    this.handleComplete();
                }
            }
        });
    }

    handleComplete() {
        this.progressIcon.classList.add('hidden');
        this.completeTarget.classList.remove('hidden');
        this.completeTarget.classList.add('animate__animated');
        this.completeTarget.classList.add('animate__fadeOut');
        setTimeout(() => {
            this.completeTarget.classList.add('hidden');
            this.completeTarget.classList.remove('animate__animated');
            this.completeTarget.classList.remove('animate__fadeOut');
        }, 1000);
    }

    handleStarted() {
        this.progressIcon.classList.remove('hidden');
    }

}