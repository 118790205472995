import {trim, isString, isObject, isArray, isRegExp, isFunction, isNull} from "lodash";

const EMAIL_REGEX = /^[a-z0-9]+([^@\s]*)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i;

export function isPromise(value) {
    return isObject(value) && isFunction(value.then);
}

export function toPromise(value) {
    if (isPromise(value)) {
        return value;
    }
    return Promise.resolve(value);
}

export const Validators =  {
    required(input) {
        const value  = trim(input.value || "");
        if (value.length === 0) {
            return {
                required: true
            };
        }
        return null;
    },
    email(input) {
        const value  = trim(input.value || "");
        if (value.length === 0 || EMAIL_REGEX.test(value)) {
            return null;
        }

        return {
            email: true
        };
    },

    pattern(pattern) {
        if (isString(pattern)) {
            pattern = new RegExp(pattern);
        }
        return (input) => {
            const value  = trim(input.value || "");
            if (value.length === 0 || pattern.test(value)) {
                return null;
            }

            return {
                pattern: {
                    pattern: pattern,
                    value: value
                }
            };
        };
    },

    max(maxValue) {
        if (isString(maxValue)) {
            maxValue = parseFloat(maxValue);
        }
        return (input) => {
            const value  = trim(input.value || "");
            
            if (value.length === 0) {
                return null;
            }

            const numberValue = parseFloat(value);
            if (numberValue > maxValue) {
                return {
                    max: {
                        expected: maxValue,
                        current: numberValue
                    }
                };
            }


            return null;
        };
    },


    min(minValue) {
        if (isString(minValue)) {
            minValue = parseFloat(minValue);
        }
        return (input) => {
            const value  = trim(input.value || "");
            
            if (value.length === 0) {
                return null;
            }

            const numberValue = parseFloat(value);
            if (numberValue < minValue) {
                return {
                    max: {
                        expected: minValue,
                        current: numberValue
                    }
                };
            }


            return null;
        };
    },

    maxLength(length) {
        if (isString(length)) {
            length = parseInt(length, 10);
        }
        return function (input) {
            const value  = trim(input.value || "");
            
            if (value.length === 0) {
                return null;
            }

            if (value.length > length) {
                return {
                    maxLength: {
                        expected:  length,
                        current: value.length
                    }
                };

            }

            return null;

        }

    },

    minLength(length) {
        if (isString(length)) {
            length = parseInt(length, 10);
        }
        return function (input) {
            const value  = trim(input.value || "");
            
            if (value.length === 0) {
                return null;
            }

            if (value.length < length) {
                return {
                    minLength: {
                        expected:  length,
                        current: value.length
                    }
                };

            }

            return null;

        }

    },

    in(list) {
        if (isString(list)) {
            list = list.split(/\,/g).map(trim);
        }
        return function (input) {
            const value  = trim(input.value || "");
            
            if (value.length === 0) {
                return null;
            }

            if (list.indexOf(value) < 0) {
                return {
                    in: {
                        list: list
                    }
                };
            }

            return null;
        }
    },
    compose(validators, bailOnFirst = false) {
        return async function (input, context = {}) {
            if (bailOnFirst) {
                return new Promise((resolve, reject) => {
                    if (!validators.some((validator) => {
                        const result = validator(input, context);
                        if (!isNull(result)) {
                            if (isPromise(result)) {
                                result.then(resolve, reject);
                            } else {
                                resolve(result);
                            }
                            return true;
                        }

                        return false;

                    })) {
                        resolve({});
                    }


                });

            }


            const results = validators.map(v => v(input, context));
            
            const hasPromise = results.some(isPromise);



            function combine(errorResults) {
                return errorResults.reduce((map, error) => {
                    if (isNull(error)) {
                        return map;
                    }
                    return Object.assign(map, error);
    
                }, {});
            }

            if (hasPromise) {
                const errorResults_1 = await Promise.all(results.map(toPromise));
                return combine(errorResults_1);
            }


            return Promise.resolve(combine(results));
        } 


    }

};



export const ValidationMessages = {
    required: ":label is required",
    pattern: ":label is in wrong format",
    max: ":label must not be more than :expected",
    min: ":label must not be less than :expected",
    maxLength: ":label is too long. :expected char(s) max",
    minLength: ":label is too short. :expected char(s) minimum",
    in: ":label must be one of :list",
    email: ":label is not a correct email address"
}

const EMPTY_MESSAGES = [];

export const ValidationMessageHelper = {
    generateMessages(error, context, customErrorMessages = {}) {
        if (!error) {
            return EMPTY_MESSAGES;
        }
        return Object.keys(error).map((key) => ValidationMessageHelper.generateMessageFor(key, error[key], context, customErrorMessages));
    },

    generateMessageFor(errorKey, errorValue, context, customErrorMessages = {}) {
        if (isObject(errorValue) && errorValue.message) {
            return errorValue.message;
        }
        
        const template = customErrorMessages[errorKey] || ValidationMessages[errorKey];
        if (!template) {
            return "Error!";
        }
        const args = {
            label: context.label
        };

        if (isObject(errorValue)) {
            Object.keys(errorValue).forEach((errorArg) => {
                const localValue = errorValue[errorArg];
                if (isArray(localValue)) {
                    args[errorArg] = localValue.join(", ");
                } else if (isRegExp(localValue)) {
                    args[errorArg] = localValue.source;
                } else {
                    args[errorArg] = String(localValue);
                }
            });
        }

        return template.replace(/\:(\w+)/, (_, key) => args[key]);
    }
}
