import { isEmpty } from 'lodash';
import BaseController from './AccountRegistration_controller';


export default class extends BaseController {
    static targets = BaseController.targets.concat(['formField', 'cardProcessor', 'registrationForm', 'signupButton']);

    connect() {
        super.connect();
        window.addEventListener("app:signup_enable", () => {
            this.enableSubmit();
        });
    }

    async submit(event) {
        event.preventDefault();
        const valid = await this.validateAll();
        this.disableSubmit();
        if (!valid) {
            this.enableSubmit();
            return;
        }
        // if (!this.isComplete()) {
        //     return;
        // }
        this.disableSubmit();
        const processor = this.element.getAttribute('data-payment-processor');
        const cardController = this.application.getControllerForElementAndIdentifier(this.cardProcessorTarget, processor);
        cardController.submitForForm(this.registrationFormTarget);
    }

    disableSubmit() {
        this.signupButtonTarget.classList.add("disabled");
        this.signupButtonTarget.setAttribute("disabled", "");
        this.signupButtonTarget.innerText = this.signupButtonTarget.getAttribute("data-disable-with");
    }

    enableSubmit() {
        this.signupButtonTarget.classList.remove("disabled");
        this.signupButtonTarget.removeAttribute("disabled");
        this.signupButtonTarget.innerText = this.signupButtonTarget.getAttribute("data-default-text");
    }

    isComplete() {
        if (!isEmpty(this.errors)) {
            return false;
        }
        if (!this.formFieldTarget) {
            return true;
        }
        return this.formFieldTargets.every((element) => {
            if (element.getAttribute("required") && isEmpty(element.value)) {
                return false;
            }
            return true;
        });
    }


}