export class ButtonController {
    constructor(target) {
        this.target = target;
        this.defaultText = target.innerHTML;
    }

    disable() {
        const disableText = this.target.getAttribute("data-disable-with");
        if (disableText) {
            this.target.innerHTML =  disableText;
        }
        this.target.classList.add("disabled");
        this.target.disabled = true;
    }

    enable() {
        this.target.innerHTML = this.defaultText;
        this.target.classList.remove("disabled");
        this.target.disabled = false;
    }
}
function findSubmitButton(element) {
    let btn =  element.querySelector('button[type="submit"]');

    if (!btn) {
        btn =  element.querySelector('input[type="submit"]');
    }
    return btn;
}
export default  {
    install(controller,  element) {
        element = element || controller.element;
        
        const btn = findSubmitButton(element);
        if (!btn) {
            const noop = function (){};
            controller.disableSubmit = controller.enableSubmit = noop;
            return {
                disable: noop,
                enable: noop
            };
        } 
        var btnController = new ButtonController(btn);
        controller.disableSubmit = function () {
            btnController.disable();
        };
        controller.enableSubmit = function() {
            btnController.enable();
        };
        return btnController;
    }
}