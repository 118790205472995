import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ['collapse', 'collapseText'];
    
    connect() {
        console.log('Connected to collapsible controller');
    }

    toggle(event) {
        event.preventDefault();
        const element = this.collapseTarget;
        if (element.className.indexOf('hidden') >= 0) {
            element.classList.remove('hidden');
            this.collapseTextTarget.textContent = this.element.getAttribute('data-hide-text') || 'Hide';
        } else {
            element.classList.add('hidden');
            this.collapseTextTarget.textContent = this.element.getAttribute('data-show-text') || 'Show';
        }
    }
}