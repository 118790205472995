import { Controller } from "stimulus";

import { extractLocalName, makeInputName } from '../src/html_utils';

import { addHiddenFieldTo } from "../src/form_helper";

import SubmitAware from "../src/submit_button_aware";

export default class extends Controller {
  static targets = [ "button", "form", "pending", "plan" ]

  connect() {
   this.submitController = SubmitAware.install(this);
    this.inputNameTemplate = this.hasPlanTarget ? (extractLocalName(this.planTarget.name) + '[:name]') : 'account[:name]';
    
    braintree.client.create({
      authorization: this.data.get("clientToken")
    }, this.clientCreated.bind(this))
  }

  useForm(element) {
    this.realForm = element;
  }

  disconnect() {
    // Remove the paypal button on disconnect
    this.buttonTarget.querySelector(".paypal-button").remove()
  }

  clientCreated(error, clientInstance) {
    if (error) {
      console.error("Error creating client", error);
      this.reenableSubmit();
      return
    }

    this.processing = true;

    braintree.paypalCheckout.create({
      client: clientInstance
    }, this.paypalCreated.bind(this))
  }

  submitForForm(formElement) {
    this.submitCalled = true;
    if (this.processing) {
      return;
    }
    if (!this.ready) {
      this.pendingTarget.classList.remove('hidden');
      this.realForm = formElement;
      return;
    }

    this.pendingTarget.classList.add('hidden');
    Rails.fire(formElement, "submit")
  }

  reenableSubmit() {
    const event = new CustomEvent('app:signup_enable', {detail: {processor: 'paypal', target: this}});
    window.dispatchEvent(event);
    this.submitController.enable();
  }

  paypalCreated(paypalCheckoutErr, paypalCheckoutInstance) {
    // Stop if there was a problem creating PayPal Checkout.
    // This could happen if there was a network error or if it's incorrectly
    // configured.
    this.processing = false;
    if (paypalCheckoutErr) {
      console.error('Error creating PayPal Checkout:', paypalCheckoutErr);
      return;
    }

    // Set up PayPal with the checkout.js library
    paypal.Button.render({
      env: this.data.get("env"), // or 'sandbox'

      // https://developer.paypal.com/docs/checkout/how-to/customize-button/#
      style: {
        color: 'gold',  // gold blue silver black
        shape: 'rect',  // shape: pill rect
        size:  'medium', // size: small medium large responsive
        label: 'pay',   // label: checkout credit pay buynow paypal installment
        tagline: false, // tagline: true false
      },

      payment: () => {
        return paypalCheckoutInstance.createPayment({
          // Your PayPal options here. For available options, see
          // http://braintree.github.io/braintree-web/current/PayPalCheckout.html#createPayment
          flow: 'vault',
        })
      },

      onAuthorize: (data, actions) => {
        this.submitController.disable();
        return paypalCheckoutInstance.tokenizePayment(data, this.paymentMethod.bind(this))
      },

      onCancel: (data) => {
        this.reenableSubmit();
        console.log('checkout.js payment cancelled', JSON.stringify(data, 0, 2));
      },

      onError: (err) => {
        console.error('checkout.js error', err);
        this.reenableSubmit();
      }
    }, this.buttonTarget).then(() => {
      // The PayPal button will be rendered in an html element with the id
      // `paypal-button`. This function will be called when the PayPal button
      // is set up and ready to be used.
    });
  }

  paymentMethod(error, payload) {
    if (error) {
      console.error("Error with payment method:", error);
      this.reenableSubmit();
      return
    }

    this.ready = true;

    this.addHiddenField(makeInputName(this.inputNameTemplate, 'processor'), "braintree");
    this.addHiddenField(makeInputName(this.inputNameTemplate, 'card_token'), payload.nonce);
    if (this.data.has("for-signup") && !this.submitCalled) {
      return;
    }

    Rails.fire(this.realForm || this.formTarget, "submit")
  }

  addHiddenField(name, value) {
    addHiddenFieldTo(this.formTarget, name, value);
  }
}

